@use "../global-variables" as *;

.border-white {
  border: solid 2px #fff;
}

.avatar-circle {
  &.initials {
    background-color: $charcoal-100; // Default color if no color is provided
    align-items: center;
    color: white;
  }
  display: flex;
  justify-content: center;
  aspect-ratio: 1;
  border-radius: 50%;
}

.avatar-circle,
.avatar-circle > img {
  border-radius: 50%;
}

.avatar-circle {
  .edit-circle {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &.small,
  &.small > img {
    width: 32px;
    height: 32px;
    font-size: 14px;
    border: solid 1px #fff;
  }
  &.small,
  &.small > img {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
  &.medium,
  &.medium > img {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  &.large,
  &.large > img {
    width: 80px;
    height: 80px;
    font-size: 28px;
  }
  &.extra-large,
  &.extra-large > img {
    width: 120px;
    height: 120px;
    font-size: 40px;
  }
  &.custom-48px,
  &.custom-48px > img {
    width: 40px;
    height: 40px;
    @media (max-width: 992px) {
      width: 48px;
      height: 48px;
      font-size: 20px;
    }
    @media (min-width: 1200px) {
      width: 48px;
      height: 48px;
      font-size: 20px;
    }
  }
}
