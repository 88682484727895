@use "../global-variables" as *;

.team-360-popover {
  padding: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &.popover {
    max-width: 400px;
  }
  &.popover.no-max-width {
    max-width: none;
  }
}

.team-leader-popover {
  padding: 16px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  .small-body-text {
    color: $charcoal-70;
  }
  &.popover-max-width-400px {
    max-width: 400px;
  }
}
