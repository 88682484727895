@use "/src/styles/global-variables" as *;

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  > .card {
    padding: 20px;
    width: 640px;
    gap: 20px;
    .login-card-info-text {
      font-size: 18px;
      color: $charcoal-100;
    }
    > .company-logo {
      height: 64px;
      align-self: baseline;
    }
  }
}

.form-group.text-input.login-input-field {
  display: block;
  > .form-label {
    margin-bottom: 4px;
  }
}

.email-field-filled {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid $sapphire-20;
  background-color: $sapphire-10;
  padding: 12px 16px;
  > p {
    font-weight: 700;
    color: $sapphire-150;
  }
}

@media screen and (max-width: 640px) {
  .login-page {
    > .card {
      width: 100%;
    }
  }
}

p.forgot-password-description-text {
  font-family: "Montserrat";
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 32px;
}

.login-page .textarea-label {
  color: black;
}

.login-input-field.has-error {
  border: 1px solid red;
}
