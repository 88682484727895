@use "../global-variables" as *;

.simple-side-nav {
  display: flex;
  gap: 20px;
  flex-direction: column;
  min-width: 240px;
  width: 240px;
  padding: 16px;
  padding-top: 24px;
  border-right: 1px solid $sapphire-20;
}

.simple-side-nav-mobile,
.simple-side-nav {
  .nav-item {
    font-weight: bold;
    color: $charcoal-100 !important;
    border-radius: 4px;
    padding: 6px 12px;
    transition: color 0.3s ease, background-color 0.3s ease;
    width: 100%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    &:hover,
    &.selected {
      border: 0;
      color: $sapphire-150 !important;
      background-color: $sapphire-20;
    }
  }
  .nested-navs {
    margin-top: 10px;
  }
  .nested-container.nested-count-2 .nav-item {
    padding-left: 20px;
    font-weight: normal;
  }
  .nested-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.nested-navs {
  margin-top: 10px;
}

.simple-side-nav-mobile {
  display: none;
  gap: 20px;
  flex-direction: column;
  transition: background-color 0.3s ease;

  &.expanded {
    background-color: white;
    padding: 16px;
  }

  .nav-item {
    color: $navy-100;
    padding: 16px 20px;
    transition: color 0.3s ease, background-color 0.3s ease;
    &:hover,
    &.selected {
      color: $sapphire-150;
      background-color: $sapphire-20;
    }
  }
}

@media (max-width: 991px) {
  .simple-side-nav.responsive {
    display: none;
  }
  .simple-side-nav-mobile {
    display: block;
  }
}

.side-nav-page {
  display: flex;
}

.side-nav-content {
  width: -webkit-fill-available;
  margin: 0 40px;
  > .card {
    gap: 24px;
    padding: 20px;
  }

  @media (min-width: 1686px) {
    width: 1366px;
    margin: 0 auto;
  }
}
