@use "../global-variables" as *;

.subscription-modal-body {
    padding: 20px;
}

.subscription-modal-upper-box {
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
}

.subscription-modal-lower-box {
    border-radius: 4px;
    border:  1px solid $sapphire-20;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.subscription-modal-please-call {
    background-color: $sapphire-10;
    border: 1px solid $sapphire-20;
    border-radius: 4px;
    padding: 12px 16px;
    font-weight: bold;
    color: $sapphire-150;
}

.subscription-modal-sales-representative {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sales-representative-contact-div {
    display: flex;
    gap: 16px;
    align-items: center;
}

.subscription-modal-upper-box-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 40px;
}

.sales-representative-image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    border: 2px solid #ECECEC;
}

.go-to-prehire {
    border: none;
}

.subscription-button-row {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}