@use "../global-variables" as *;

.team-members-table {
  th {
    color: #53565a;
    font-weight: 200;
  }
  thead {
    border-bottom: 1px solid #ddddde;
  }
  tbody > tr {
    &.deactivated {
      color: $charcoal-100;
      .team-member-name {
        color: $charcoal-100;
        opacity: 0.7;
      }
    }

    > td {
      padding-bottom: 16px;
    }
  }

  .team-member-name {
    margin: 7px 0 5px 12px;
    color: #001f4e;
    font-size: 14px;
    font-weight: bold;
  }
  .tr-spacer {
    height: 10px;
  }
}
