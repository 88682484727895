@use "../global-variables" as *;

.instance-picker {
  .form-check {
    margin: 16px 20px;
    display: flex;
    gap: 8px;
    font-size: 14px;
    color: $navy-100;
  }
  .time-interval-option-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .instance-option {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-left: 20px;
  }
  .form-check-input {
    margin-left: 0;
    border-color: $charcoal-40;
    &:checked {
      background-color: $sapphire-100;
      border-color: $sapphire-100;
    }
  }
  .is-disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: not-allowed;
    transition: opacity 0.3s ease-in-out;
  }
}
