@use "../global-variables" as *;

.side-drawer.card {
  margin-top: -20px;
  position: fixed;
  padding: 20px;
  z-index: 800;
  z-index: 9999;
  height: calc(100vh - 158px);
  overflow-y: scroll;
  transition: right 0.5s ease-out;
  right: -800px;
  overflow: auto;
  &.active {
    right: 0;
  }
}

.side-drawer.quick-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  height: -webkit-fill-available;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 25;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.quick-actions.slightly-open {
  transform: translateX(98%);
}

.side-drawer.quick-actions.open {
  transform: translateX(0);
}

.quick-action-card.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  align-items: center;
  text-align: center;
  &.disabled {
    opacity: 0.5;
  }
}

.quick-action-card-corner-tag {
  z-index: 1;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}

.quick-action-button {
  transform: rotate(-90deg) translateY(100%);
  transform-origin: bottom right;
  position: fixed;
  right: 40px;
  top: 35vh;
  background-color: #d5f5f6;
  color: #1f8e93;
  padding: 8px 12px;
  border-radius: 4px;
  z-index: 501;
  border: none;
  box-shadow: #53565a 0px 4px 4px 0px;
  transition: transform 0.3s ease-out;
}

.quick-action-button.open {
  transform: rotate(-90deg) translateY(-360px);
}

.quick-action-button.slightly-open {
  transform: rotate(-90deg) translateY(80%);
}

.quick-action-button:hover {
  background-color: #1f8e93;
  color: #d5f5f6;
}

.side-drawer-back-button {
  border: none;
  background-color: transparent;
  padding-left: 10px;
  color: #425cc7;
}

.sample-reports-header {
  color: #53565a;
}
