@use "../global-variables" as *;

.likert-single-scale {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.likert-single-scale.previous {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  position: absolute;
}

.likert-single-scale.active {
  opacity: 1;
  transform: translateY(0);
  position: static;
}

.likert-single-scale.next {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translateY(20px);
}

.responsive-button-footer {
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  @media (max-width: 576px) {
    flex-direction: column-reverse;
    gap: 20px;
    button {
      width: 100%;
    }
    > div {
      width: 100%;
    }
    &.inverse-direction {
      flex-direction: column;
    }
  }
}

// Display grid with some spacing between the columns
.likert-scale-options-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
  @media (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
}

.likert-scale-option {
  border: solid 1px $charcoal-50;
  padding: 12px;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s ease, border 0.2s ease;
  display: flex;
  align-items: center;
  height: 75px;
  &:hover,
  &.selected {
    background-color: $sapphire-10;
    border-top: solid 2px $sapphire-100;
    border-left: solid 2px $sapphire-100;
    border-right: solid 2px $sapphire-100;
    border-bottom: solid 6px $sapphire-100;
  }
  > input {
    display: none;
  }
}

.likert-scale-description {
  color: black;
}

.likert-scale-instructions {
  color: $charcoal-100;
  font-size: 14px;
}
