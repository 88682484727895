@use "/src/styles/global-variables" as *;

.card.activity-monitor-container,
.card.activity-monitor-box {
  padding: 20px;
}

.card.activity-monitor-container {
  gap: 24px;
}

.activity-monitor-container__title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.activity-monitor-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(233px, 1fr));
  gap: 24px;
}

.card.activity-monitor-box {
  gap: 8px;
  .change-since-last {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 16px;
    color: $sapphire-150;
  }
  .status-dot {
    width: 16px;
    height: 16px;
  }
}

.activity-monitor-box__title {
  color: $charcoal-100;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.activity-monitor-box__body {
  display: flex;
  gap: 16px;
  align-items: baseline;
  margin-top: auto;
}

.activity-monitor-box__main-value {
  color: $sapphire-150;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.activity-monitor-box__description {
  display: flex;
  gap: 8px;
  align-items: center;
}

.activity-monitor-container__empty-content {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  background-color: $sapphire-05;
  text-align: center;
  color: $sapphire-150;
  > .title {
    font-weight: 700;
    font-size: 16px;
  }
  > .description {
    max-width: 480px;
    margin-top: 8px;
  }
  > .action-button-container {
    margin-top: 24px;
  }
}

.info-hover-over-element {
  font-size: 14px;
  font-weight: 700;
  color: $sapphire-100;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  > .info-icon {
    font-size: 16px;
  }
}

.activity-monitor-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
