@use "../global-variables" as *;

.onboarding-modal-title {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  img {
    height: 100px;
    width: 100px;
  }
}

.onboarding-modal-body {
  display: flex;
  flex-direction: column;
  background-color: $charcoal-05;
  gap: 20px;
  padding: 20px;
  border-radius: 4px;
}

.onboarding-modal-body-content {
  border-bottom: solid 1px $sapphire-20;
  > ul {
    padding-left: 0;
    display: flex;
    gap: 12px;
    flex-direction: column;
    > li {
      display: flex;
      gap: 12px;
      list-style: none;
      > svg {
        margin-top: 3px;
      }
    }
  }
}

.onboarding-modal-body-header {
  border-bottom: solid 1px $sapphire-20;
  padding-bottom: 20px;
}

.onboarding-modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  > button {
    max-width: 100%;
    width: 500px;
  }
}

.setup-wizard-card.active,
.setup-wizard-card:hover {
  background-color: $sapphire-10;
  border: 1px solid $sapphire-100;
  transition: border 0.3s, background-color 0.3s;
}

.setup-wizard-card.card {
  padding: 16px;
  background-color: $sapphire-05;
  align-items: center;
  text-align: center;
  &.active,
  &:hover {
    background-color: $sapphire-10;
    border: 1px solid $sapphire-100;
    transition: border 0.3s, background-color 0.3s;
  }
  & > .small-square-icon {
    margin-bottom: 12px;
  }
}

.setup-wizard-card-description {
  color: $charcoal-100;
}

.setup-wizard-modal-card {
  padding: 32px;
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: $sapphire-05;
  flex-direction: column;
  border-radius: 4px;
}

.setup-wizard-modal-card-title {
  color: $sapphire-150;
  font-size: 16px;
}
