@use '../global-variables' as *;

// To update the colors see: https://fkhadra.github.io/react-toastify/how-to-style/
:root {
  --toastify-color-light: #eaf9fa !important;
  --toastify-text-color-light: #202d63 !important;
  --toastify-icon-color-success: #001f4e !important;
  --toastify-color-progress-success: #166669 !important;
}

.Toastify__toast {
  > .Toastify__close-button {
    align-self: center;
    > svg {
      display: inline-block;
      height: 20px;
      width: 20px;
    }
  }
  svg {
    color: #001f4e !important;
  }
}
