@use "../global-variables" as *;

.global-nav-bar.navbar {
  background: $charcoal-05;
  padding: 0 10px;
  height: 60px;
  border-bottom: 1px solid $sapphire-20;
  // add responsive styles
  @media (max-width: 991px) {
    height: auto;
  }

  .nav-link {
    text-align: center;
    height: 100%;
    padding: 18px 0;
    color: $charcoal-100;
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    &.active {
      color: $sapphire-150;
      transition: box-shadow 0.3s;
      box-shadow: inset 0 -4px 0 0 $sapphire-150;
    }
    &.not-active {
      color: $charcoal-100;
      box-shadow: none;
    }
    &.show {
      color: $charcoal-100;
    }
  }

  .css-k9d9pq-control {
    background-color: $charcoal-05;
  }
  .navbar-nav {
    align-items: center;
    display: flex;
    gap: 24px;
    @media (max-width: 768px) {
      gap: 10px;
      margin-left: 0;
    }
  }

  #user-settings {
    padding: 0;
    display: flex;
    align-items: center;
    color: $sapphire-150;
  }
  .dropdown-menu {
    margin-top: 0px;
  }
}

.navbar-expand .navbar-nav .dropdown-menu {
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  padding: 0;
}

.nav-link.dropdown-toggle::after {
  position: relative;
  top: 2px;
}

[aria-labelledby="user-settings"].dropdown-menu,
[aria-labelledby="notification-dropdown"].dropdown-menu {
  right: 0;
  left: auto !important;
  margin-top: 10px !important;
}

[aria-labelledby="notification-dropdown"].dropdown-menu {
  width: 360px;
}

.library-nav-dropdown.dropdown {
  > .dropdown-menu {
    &:hover {
      display: block;
    }
    a {
      padding: 16px;
    }
    margin-top: 0;
  }
}

.guide-dropdown > .dropdown-menu {
  min-width: 280px;
  padding: 12px;
  > .dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $navy-100;
    border-bottom: 1px solid $sapphire-20;
    font-size: 14px;
    &:last-of-type {
      border-bottom: none;
    }
    &.active,
    &:active {
      background-color: $sapphire-05;
    }
  }
  > span {
    padding-top: 16px;
    padding-left: 20px;
    padding-bottom: 0;
    color: $navy-100;
    display: block;
    font-size: 12px;
  }
}

.nav-help > path {
  fill: white;
}

.notification-box {
  .notification-buttons {
    display: flex;
    justify-content: space-between;
  }
  .notification {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid $sapphire-20;
    > p {
      margin-bottom: 0;
    }
  }
}

.navbar-toggle > .navbar-toggler-icon {
  background-image: url(../../resources/icons/sapphire-hamburger-icon.svg);
}

.nav-bar-vertical-line {
  background-color: $charcoal-50;
  height: 40px;
  width: 1px;
  @media (max-width: 766px) {
    display: none;
  }
}

.beta-tag {
  color: white;
  background-color: $navy-60;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: bold;
}
