@use "../global-variables" as *;

.page.search {
  > .card {
    padding: 20px;
  }
}

.search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .company-name {
    color: $charcoal-70;
  }
  .button {
    height: fit-content;
  }
}

.card.single-search-card {
  text-align: center;
  > h3 {
    color: black;
    margin-bottom: 4px;
    width: 100%;
    @include line-clamp(1);
  }
  > span {
    color: $charcoal-100;
  }
  .tag {
    font-size: 14px;
    color: $sapphire-130;
    font-weight: bold;
    background-color: $sapphire-10;
    border-radius: 4px;
    padding: 4px 8px;
    margin-bottom: 16px;
    &.pending {
      color: $charcoal-100;
      background-color: $charcoal-20;
    }
  }
  .view-guide {
    margin-top: auto;
    width: 100%;
  }
  .job-title {
    @include line-clamp(1);
    color: $charcoal-100;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
    font-size: 14px;
  }
  padding: 20px;
  display: flex;
  align-items: center;
}

.search-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

@media (min-width: 1200px) {
  .page.search {
    grid-template-columns: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .page.search {
    grid-template-columns: 960px;
  }
  .search-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .page.search {
    grid-template-columns: 720px;
  }
  .search-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

// 767 and below
@media (max-width: 767px) {
  .page.search {
    grid-template-columns: 540px;
  }
  .search-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
