@use "../global-variables" as *;

.section {
  padding: 20px;
  h2 {
    margin-bottom: 0;
  }
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
}

.section-modules {
  gap: 20px;
  display: grid;
}

.module {
  padding: 10px 20px 20px 20px;
  .module-title {
    > h3 {
      margin-bottom: 0;
    }
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    span {
      white-space: nowrap;
    }
  }
  .free-text {
    font-size: 14px;
  }
}

.module-type {
  display: flex;
  font-weight: 700;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.5px;
  border-radius: 4px;
  width: auto !important;
  color: $sapphire-150;
  background: $sapphire-05;
}

.module-library-card-description {
  @include line-clamp(2);
  color: $charcoal-100;
  font-size: 12px;
  min-height: 29px;
}

.module-type.FreeText {
  color: $poppy-130;
  background: $poppy-10;
}

.module-type.ShortList,
.module-type.List {
  color: $sky-130;
  background: $sky-10;
}

.module-type.LongList {
  color: #6a002b;
  background: #f7ccdd;
}

.module-type.Resource {
  color: $charcoal-100;
  background: $charcoal-20;
}

.module-library-card.card {
  transition: box-shadow 0.3s;
  min-height: 112px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.added {
    background: $charcoal-05;
    > .module-library-card-icon {
      background: $charcoal-10;
      > svg {
        color: $charcoal-100;
      }
    }
    p,
    h4 {
      color: $charcoal-100;
    }
    .module-type {
      background-color: $charcoal-20;
      color: $charcoal-100;
    }
  }

  &.informational {
    background-color: $sapphire-05;
    border: none;
    flex-direction: column;
    gap: 4px;
    color: $sapphire-150;
    font-size: 13px;
    > .help-icon {
      font-size: 20px;
      align-self: baseline;
    }
  }

  &.informational,
  &.added {
    cursor: default;
    box-shadow: none !important;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  .module-library-card-icon {
    min-width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: $sapphire-05;
    margin-right: 12px;
    > svg {
      font-size: 32px;
      color: $sapphire-150;
    }
  }
  h4,
  p {
    margin-bottom: 0;
  }
  p {
    line-height: 14.5px;
  }
  .module-library-card-text {
    flex-grow: 1;
  }
  .module-library-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 7px;
  }
}

.module-card-holder {
  display: grid;
  grid-template-columns: repeat(2, minmax(330px, 1fr));
  gap: 12px;
  margin-bottom: 14px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.module-form-card {
  padding: 12px 16px;
  textarea {
    min-height: 88px;
  }
}

// ---- Module Modal ----
.edit-module-modal,
.add-module-modal,
.simple-modal,
.edit-information-modal {
  .modal-content {
    padding: 20px;
    gap: 20px;
  }
  .modal-title-row {
    display: flex;
    justify-content: space-between;
    z-index: 1;
    h2 {
      width: auto;
      display: inline;
      margin-bottom: 0;
    }
    svg {
      color: $sapphire-100;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.simple-modal-footer-buttons {
  display: flex;
  justify-content: space-between;
}

.simple-modal-text {
  max-width: 640px;
  text-align: center;
}

.section-collapse {
  width: 100%;
}

.simple-link {
  text-decoration: none;
}
