@use "../global-variables" as *;

.edit-circle {
  &:hover {
    background-color: $sapphire-20;
  }
  display: flex;
  justify-content: center;
  transition: background-color 0.3s;
  color: $sapphire-100;
  background-color: $sapphire-10;
  border: solid 1px $neutral-white-100;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  align-items: center;
  &.small {
    height: 24px;
    width: 24px;
    font-size: 12px;
  }
}

.section-container {
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid $sapphire-20;
  > .section-body-row:last-of-type {
    border-bottom: none;
  }
  &.no-border-bottom {
    border-bottom: none;
  }
}

.section-body-row {
  align-items: center;
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid $sapphire-20;
  color: $sapphire-100;
  font-weight: bold;
  padding: 16px 20px;

  span {
    display: inline;
    width: auto;
  }

  .section-body-row-name {
    font-size: 14px;
  }
  .team-role {
    @include list-span();
  }
}

.pending-member-row {
  font-size: 14px;
  color: $charcoal-100;
  font-weight: normal;
  > .name {
    font-weight: bold;
  }
}

.section-body-name-and-description {
  display: flex;
  flex-direction: column;
  gap: 4px;
  > .section-body-row-description {
    font-size: 12px;
    color: $charcoal-100;
    font-weight: normal;
    align-items: center;
    display: flex;
  }
}

.section-row-count {
  align-self: center;
  background-color: $sapphire-05;
  color: $sapphire-100;
  border-radius: 4px;
  padding: 6px 12px;
  margin-top: 16px;
}

.my-teams-card-join-create-buttons {
  display: flex;
  gap: 12px;
}

.no-teams-joined-yet {
  background-color: $charcoal-05;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.build-your-user-guide-card.card {
  background: $sapphire-05;
  align-items: center;
  padding: 20px 0px;
  > .module-card-holder {
    width: 100%;
    padding: 20px;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}

// ------------ Modals --------------
.welcome-modal {
  .modal-content > .col {
    padding: 40px 0px;
    width: 400px;
    align-self: center;
  }

  h1 {
    font-size: 28px;
  }
  p {
    font-size: 12px;
    color: #202d63;
  }
}

.invite-modal {
  .modal-content {
    padding: 20px;
  }
}

.warning-modal {
  color: $navy-100;
  padding: 10px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h2 {
    color: $unknown-1;
    display: inline;
    width: auto;
    margin-bottom: 0;
  }
  .warning-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .warning-modal-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .warning-modal-buttons {
    align-self: center;
    margin: 10px 0px;
  }
}

.edit-information-modal {
  .edit-information-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .edit-information-modal-body {
    // margin-top: 12px;
  }
  .edit-information-modal-footer {
    display: flex;
    justify-content: flex-end;
    // margin-top: 10px;
  }
  .card {
    padding: 10px;
    margin-bottom: 10px;
  }
}

.edit-information-form {
  .form-group {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
