@use "../global-variables" as *;

.guide-header {
  &.card {
    background-color: $charcoal-10;
    position: relative;
    display: grid;
  }

  .avatar-circle.extra-large {
    margin-top: -101px;
  }
  > .edit-circle {
    position: absolute;
    top: 0;
    right: 0;
  }

  .guide-header-chin {
    background-color: white;
    padding: 20px 24px 0px 32px;
    display: flex;
    justify-content: space-between;
  }

  .profile-information {
    display: flex;
    gap: 20px;
  }
  .header-description {
    display: flex;
    align-items: center;
    color: $charcoal-100;
    font-size: 14px;
  }

  > .cover-photo-placeholder {
    width: 100%;
    height: 180px;
    background-size: cover;
  }
}

.section-title {
  font-size: 20px;
  color: $sapphire-150;
  font-weight: 700;
}

.section .contact-buttons {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.connect-modules {
  display: grid;
  gap: 20px;
}

.leaders-container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  > .leader-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div:first-of-type {
      display: flex;
      gap: 12px;
      flex-grow: 1;
    }
  }
}

.card.team-lead {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .member-name {
    @include line-clamp(1);
    color: $sapphire-150;
    margin-bottom: 0;
  }
  .member-role {
    color: $charcoal-100;
    font-size: 12px;
    @include line-clamp(1);
  }
}

.section.team-members {
  > .section-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  > .team-member-count {
    align-self: center;
    background-color: $sapphire-05;
    color: $sapphire-100;
    border-radius: 4px;
    padding: 6px 12px;
    margin-top: 16px;
  }
}

.automatic-tag {
  display: flex;
  font-weight: 700;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.5px;
  border-radius: 4px;
  color: $cyan-150;
  background: $cyan-10;
  margin-right: 8px;
  padding: 3px 4px;
}

.automatic-team-info {
  background: $sapphire-05;
  padding: 12px 16px;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.team-member-card-no-button {
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .name {
    @include line-clamp;
    padding-top: 12px;
    font-size: 14px;
    color: $sapphire-150;
    font-weight: 700;
    max-width: 100%;
  }
  .role {
    @include line-clamp;
    padding-top: 4px;
    font-size: 12px;
    color: $charcoal-100;
  }
}

.team-member-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
@media screen and (max-width: 991px) {
  .team-member-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .team-member-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

.team-member-card.card {
  display: flex;
  align-items: center;
}

.team-members-full-card.card,
.team-member-card.card,
.team-settings.card {
  padding: 20px;
}

.team-members-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.team-members-full-card {
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.greyed-out-box {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.team-member-card-name {
  font-weight: bold;
}

.team-member-card-jobTitle {
  color: #53565a;
  height: 24px;
}

.team-member-card {
  button {
    width: 100%;
    margin-top: 1rem;
  }
}

.team-settings {
  .team-settings-header {
    display: flex;
    align-items: center;
  }
  .team-member-count {
    font-size: 14px;
  }
}

.team-member-count {
  color: #828283;
}

.split-cards {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 20px;
  > .vertical-line {
    background-color: $sapphire-20;
  }
  > div > p {
    color: $charcoal-100;
  }
}

.pending-tag {
  @include list-span;
  color: $charcoal-100;
  background-color: $charcoal-20;
}
// -------------- Page --------------

.guide {
  .guide-body {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 400px;
  }
  .private-guide-body {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .header-buttons,
  .guide-header {
    grid-column: span 2;
    margin-bottom: 20px;
    z-index: 99;
    overflow: hidden;
    padding: 0;
    gap: 0;
  }
  .header-buttons {
    display: flex;
    justify-content: space-between;
    > .header-buttons-group {
      margin-left: auto;
    }
  }
  .sections,
  .side-bar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .list-span.lg {
      white-space: wrap;
    }
  }
}

.add-module-banner.warning-banner {
  p {
    font-weight: 700;
  }
  grid-column: span 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    justify-self: flex-start;
    gap: 16px;
  }
}

@media (min-width: 1200px) {
  .page.guide {
    grid-template-columns: 1140px;
    .guide-body {
      grid-template-columns: 720px 400px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .page.guide {
    grid-template-columns: 960px;
    .guide-body {
      grid-template-columns: 540px 400px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .page.guide {
    grid-template-columns: 720px;
  }
}

@media (min-width: 560px) and (max-width: 767px) {
  .page.guide {
    grid-template-columns: 540px;
  }
}

@media (max-width: 991px) {
  .page.guide {
    .guide-body > .sections,
    .guide-body > .side-bar {
      grid-column: span 2;
    }
  }
}

@media (max-width: 559px) {
  .page.guide {
    padding: 20px;
    padding-top: 0;
    .guide-body > .sections,
    .guide-body > .side-bar {
      grid-column: span 2;
    }
  }

  .build-your-user-guide-card.card > .module-card-holder,
  .page.guide .guide-body,
  .page.guide {
    grid-template-columns: 1fr;
  }
}

// ------------ End Page --------------

// -------------- Modals --------------

.create-team-modal {
  .modal-content {
    padding: 20px;
  }
  button[type="submit"] {
    margin-top: 20px !important;
  }
}

.form-label-with-toggle {
  display: flex;
  gap: 12px;
}

.collapsible-card-container > div:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: $sapphire-20 1px solid;
}

.action-checklist-drawer.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: 60px;
  z-index: 1000;
  height: calc(100% - 40px);
  width: 400px;
  .side-drawer-title {
    font-size: 20px;
  }
  .simple-collapsible-card__title {
    > h2 {
      font-size: 16px;
    }
  }
}

.job-candidate-profile-card.card {
  padding: 20px;
  gap: 20px;
}

.job-candidate-profile-card__info-card.card {
  padding: 12px 16px;
  > h3 {
    margin-bottom: 8px;
  }
}

.setting-row-action {
  display: flex;
  align-items: center;
  gap: 16px;
}

.visibility-banner {
  margin-bottom: 20px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    justify-self: flex-start;
  }
}

.company-guide-full-teamscan-report {
  grid-column: 2 span;
  .card-wrapper {
    padding: 20px;
    gap: 20px;
    display: flex;
  }
}

.overflow-container-wrapper.header-guide-nav-container {
  .simple-nav.nav {
    flex-wrap: nowrap;
  }
  .nav-link {
    white-space: nowrap;
  }
  .overflow-container {
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 767px) {
      max-width: 485px;
    }
    @media (max-width: 559px) {
      max-width: calc(100vw - 40px);
    }
  }
}

.responsive-two-column-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.team-personality-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // @ 1199 and bellow
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
}
