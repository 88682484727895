@use '../global-variables' as *;

.ai-module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: $sapphire-05;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  .ai-module-text {
    font-weight: 700;
    line-height: 20px;
  }
  .ai-module-author {
    text-align: left;
  }
  .ai-module-invisible-button {
    border: none;
    background: none;
  }
  .ai-module-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .ai-module-title-row {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .beta-badge {
    font-size: 12px;
    font-weight: bold;
    background-color: #ccf2e1;
    color: #009952;
    padding: 3px 4px;
    border-radius: 4px;
    width: 35px;
    margin-left: 10px;
    margin-bottom: 7px;
  }

  .generate-feedback {
    margin-top: 10px;
  }

  .form-label {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .dropdown {
    display: flex;
    flex-direction: row-reverse;
  }

  .critter-message-bubble {
    animation: 'fade-in' 10s ease-in-out;
    padding: 20px;
    background-color: #f5f7fc;
    margin: 10px;
    border-radius: 20px;
  }

  .critter-message-bubble-2 {
    padding: 20px;
    background-color: #f5f7fc;
    margin: 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .skill-coach-textarea {
    border-radius: 4px;
    padding: 8px 8px 8px 12px;
    height: 88px;
    border: solid 1px #a9aaac;
    background-color: #fff;
    margin: 10px;
  }
  .critter-message-step {
    font-size: 12px;
    color: #53565a;
  }
  .user-message-bubble {
    padding: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #f7f7f7;
    margin: 10px;
  }

  .user-message-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .skill-coach-content-box {
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .skill-coach-messages-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    scroll-behavior: smooth;
    overflow-y: auto;
  }

  .skill-coach-user-input-box {
    display: flex;
    flex-direction: column;
  }

  .swipe-up-transition {
    transition: all 0.4s ease-in-out;
    transform: translateY(-74%);
  }


  .dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
}