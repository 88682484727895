@use "../global-variables" as *;

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.notification-bell-with-number {
  width: fit-content;
  position: relative;
  color: $sapphire-20;
  font-size: 24px;
  > .notification-count {
    position: absolute;
    top: 3px;
    right: -5px;
    background-color: $ruby-100;
    color: white;
    border-radius: 50%;
    aspect-ratio: 1;
    width: 15px;
    height: 15px;
    border: solid 1px $sapphire-20;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
  &.pulsate {
    animation: pulse-animation 1.5s infinite ease-in-out;
  }
}

#notification-dropdown {
  &::after {
    display: none;
  }
}
