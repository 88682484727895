.overflow-container {
  width: 100%; /* Set desired width */
  height: 100%; /* Set desired height */
  overflow: auto;
  position: relative;
  display: flex;
  align-items: stretch;
  > table {
    width: 100%;
  }
}

.overflow-container-wrapper {
  width: 100%; /* Set desired width */
  height: 100%; /* Set desired height */
  position: relative;
  &.gradient-left::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
    z-index: 1;
  }
  &.gradient-right::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
  }
}
