@use "../global-variables" as *;

.prompt-cards-container {
  // we will have a max of 3 cards in a row all being the same width
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-gap: 20px;
  // when the screen size gets to 1199px or smaller then we have 1 card per row
  @media (max-width: 1199px) {
    grid-template-columns: 1fr;
  }
}

.coachbot-prompt-page {
  .card {
    padding: 20px;
  }
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0px 20px;
}

.coachbot-prompt-card-illustration {
  height: 150px;
}

.coachbot-prompt-card.card {
  padding: 0;
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  transition: border 0.3s ease;
  overflow: hidden;

  &:hover,
  &.selected {
    border: 1px solid $sapphire-100;
    .coachbot-prompt-description {
      background-color: $sapphire-10;
    }
  }
}

.coachbot-prompt-description {
  background-color: $charcoal-05;
  padding: 20px;
  margin-top: auto;
  width: 100%;
  transition: background-color 0.3s ease;
  height: 128px;
}

.coachbot-prompt-page-header.card {
  gap: 20px;
}
.coachbot-prompt-page-header-description {
  background-color: $charcoal-05;
  padding: 20px;
}
