@use "/src/styles/global-variables" as *;

.admin-banner {
  display: flex;
  background-color: $sapphire-05;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  p {
    color: $sapphire-150;
  }
}

// Hidden Admin console styles
.admin-console-table-header {
  font-weight: 700;
  min-width: 116px;
}

.admin-console-table-header.warning {
  background: $yellow-20;
}

.admin-console-table-card {
  .preview-toggle {
    color: $sapphire-150;
    display: inline;
    font-weight: 400;
  }
}

.admin-console-table {
  .pending-cell {
    opacity: 0.5;
  }
}

.admin-console-modal {
  .upload-status-card {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.page.admin-console {
  > .card {
    padding: 20px;
  }
}

@media (min-width: 1200px) {
  .page.admin-console {
    grid-template-columns: 1140px;
  }
  .analytics-sticky-footer {
    width: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .page.admin-console {
    grid-template-columns: 960px;
  }
  .analytics-sticky-footer {
    width: 960px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .page.admin-console {
    grid-template-columns: 720px;
  }
  .analytics-sticky-footer {
    width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .page.admin-console {
    grid-template-columns: 540px;
  }
  .analytics-sticky-footer {
    width: 540px;
  }
}

@media (max-width: 1199px) {
  .visibility-settings-container > .multi-card-container-same-width {
    flex-direction: column;
  }
}

.card {
  > .analytics {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.analytics-description-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
}

.survey-config-form {
  flex-direction: column;
  display: flex;
  gap: 8px;
}

.analytics-summary-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    margin-bottom: 0;
  }
  > .count {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .low-and-high-summary-section {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    gap: 20px;
    padding-top: 20px;
    > .vertical-line {
      background-color: $sapphire-20;
    }
    > div > p {
      color: $charcoal-100;
    }
    .snapshot-box {
      flex-grow: 1;
    }
    // when screen is small, stack the boxes
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      > .vertical-line {
        width: 100%;
        height: 1px;
      }
    }
  }
}

.analytics-variance-legend {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

svg.top {
  color: $sapphire-100;
}
svg.median {
  color: $cyan-100;
}
svg.bottom {
  color: $ruby-100;
}

.small-user-card {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: $charcoal-05;
  padding: 12px 16px;
  width: 100%;
  > .small-user-card-text {
    > h4 {
      font-size: 14px;
      margin-bottom: 0;
    }
    > span {
      font-size: 12px;
    }
  }
}

.analytics-sticky-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  mask: linear-gradient(transparent, black, black);
  backdrop-filter: blur(10px);
  background: linear-gradient(180deg, transparent, $charcoal-05 8%);
  z-index: 999;
  padding: 70px 18px 18px;
  align-items: center;
  > div {
    display: flex;
    gap: 12px;
  }
  @media (max-width: 1199px) {
    padding-top: 20px;
  }
}

.analytics-filter-drawer-body {
  .checkbox-group {
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  input[type="checkbox"] {
    margin-right: 8px;
  }
}

.filter-span {
  @include list-span;
  &.active,
  &:hover {
    color: white;
    background-color: $sapphire-100;
  }
}

.free-text-insights-modal {
  .collapsible-card {
    background-color: $sapphire-05;
    border-radius: 4px;
    padding: 12px 16px;
  }
  .collapsible-cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .user-feedback {
    display: flex;
    gap: 12px;
  }
  .author {
    color: $charcoal-100;
  }
  .quote {
    color: black;
    margin-bottom: 8px;
  }
}

.anonymous-user {
  height: 32px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sapphire-20;
}

.deactivate-user-modal-body {
  text-align: center;
  > span {
    font-weight: bold;
    font-size: 16px;
  }
  > p {
    font-size: 14px;
  }
}
.deactivate-user-modal-footer {
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  margin-top: 12px;
}

.updated-teams {
  padding: 16px;
  background: $sapphire-05;
  border-radius: 4px;
  > ul {
    margin-bottom: 0;
    > li {
      list-style: none;
    }
  }
}

.department-name-tag {
  color: $sapphire-150;
  background-color: $sapphire-05;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  width: fit-content;
}

.team-reflection-div {
  display: flex;
  align-items: center;
  gap: 24px;
  > .team-reflection-info {
    gap: 8px;
  }
}

.label-tag {
  padding: 4px 8px;
  border-radius: 4px;
  margin: 5px;
  font-weight: bold;
  font-size: 12px;
  width: fit-content;
  height: fit-content;
  &.lg,
  &.bigger {
    font-size: 14px;
  }
  &.green {
    color: $indicator-success-green-120;
    background-color: $indicator-success-green-20;
  }
  &.blue {
    color: $sapphire-100;
    background-color: #d9e1ff;
  }
  &.personality-tag {
    color: $cyan-150;
    background-color: $cyan-30;
  }
  &.yellow {
    color: $gold-130;
    background-color: $yellow-20;
  }
  &.orange {
    color: $poppy-130;
    background-color: $poppy-10;
  }
  &.red {
    color: $indicator-error-red-120;
    background-color: $indicator-error-red-10;
  }
  &.grey {
    color: $charcoal-100;
    background-color: $charcoal-10;
  }
  &.info-sapphire {
    color: $sapphire-160;
    background-color: $sapphire-20;
  }
}

.admin-console-table-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 10px;
}

.clickable-card.card {
  padding: 20px;
  transition: border 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  &:hover,
  &.selected {
    border: 1px solid $sapphire-100;
    .coachbot-prompt-description {
      background-color: $sapphire-10;
    }
  }
  &.selected.v1 {
    background-color: $sapphire-05;
  }
}

.test-taker-match-card {
  .dot-divider {
    font-size: 4px;
    margin: 0 4px;
  }
  &.card {
    padding: 16px 32.5px 12px 21.5px;
    flex-direction: row;
    justify-content: space-between;
  }
}

.test-taker-match-card__main-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.test-taker-match-card__description > p {
  color: $charcoal-100;
  display: flex;
  align-items: center;
}

.test-taker-match-card__title {
  display: flex;
  gap: 8px;
}
.test-taker-match-card__title__name {
  color: "black";
}

.test-taker-match-card-date {
  margin-top: -2px;
  > p {
    color: $charcoal-70;
    display: flex;
    align-items: center;
  }
}

.card.test-taker-match-card-container {
  padding: 16px;
  background-color: $charcoal-05;
  gap: 6px;
  max-height: 514px;
  overflow-y: auto;
}

.visibility-setting-card.card {
  flex-direction: row;
  gap: 12px;
  .icon-container {
    font-size: 32px;
    background-color: $sapphire-05;
    color: $sapphire-150;
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  .description-text {
    color: $charcoal-100;
    min-height: 30px;
  }

  &.selected {
    .icon-container {
      background-color: $sapphire-05;
    }
    background-color: $sapphire-10;
  }
}

.visibility-table-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-bottom: 1px solid $charcoal-20;
  .permission-chart-table {
    table-layout: fixed;
  }
}

.admin-console-settings-card {
  padding: 20px;
  gap: 24px;
}

.admin-console-settings-card-subheader {
  color: $charcoal-100;
}

.turn-off-automatic-teams-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;

  .toggle-row {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 16px;
  }
}

.turn-on-automatic-teams-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.out-of-5-span-tag {
  padding: 2px 6px;
  color: black;
  border-radius: 4px;
  background-color: $sapphire-10;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid $sapphire-100;
  &.green {
    border-color: $indicator-success-green-100;
    background-color: $indicator-success-green-20;
  }
  &.yellow {
    border-color: $gold-100;
    background-color: $yellow-20;
  }
  &.poppy {
    border-color: $poppy-100;
    background-color: $poppy-20;
  }
  &.red {
    border-color: $indicator-error-red-100;
    background-color: #f7ccdd;
  }
  &.light-green {
    background-color: rgba(156, 204, 64, 0.2);
    border-color: #9ccc40;
  }
}

.checkin-score-popover.popover {
  max-width: 368px;
}

ul.check-in-response-list {
  padding-left: 24px;
  font-size: 14px;
  li::marker {
    font-size: 18px;
  }
}
