@use "../global-variables" as *;

button.btn {
  font-weight: 700;
  &.sm {
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
  }
  &.lg {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 12px;
  }
  &.dropdown-toggle {
    font-weight: normal;
  }
}

// Overriding Bootstrap classes to match criteria
button.btn-outline-primary {
  color: $sapphire-100 !important;
  border: 1px solid $sapphire-100;
  &:hover {
    background-color: $sapphire-10;
    border: 1px solid $sapphire-100;
  }
  &:active,
  &.active,
  &.show {
    background-color: $sapphire-20 !important;
    border: 1px solid $sapphire-100;
  }
}

button.btn-outline-primary.link {
  padding: 0;
  border: none;
  &:hover {
    text-decoration: underline;
  }
  &:active,
  &:hover,
  &.active {
    background-color: transparent;
    border: none;
  }
}

button.btn.white-button {
  background-color: white;
}

button.btn-primary {
  background-color: $sapphire-100;
  &:hover {
    background-color: $sapphire-90;
  }
  &:active,
  &:focus,
  &:focus-visible {
    background-color: $sapphire-130 !important;
  }
  &.disabled,
  &:disabled {
    background-color: $sapphire-30;
    border: none;
  }
}

button.btn-outline-primary.tertiary-blue {
  background-color: $sapphire-05;
  border: none;
}

button.btn-outline-primary.link {
  color: red;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:active,
  &:hover,
  &.active {
    background-color: transparent;
    border: none;
  }
}

.dropdown-toggle.btn.btn-light {
  background-color: white;
  text-align: left;
  border: $sapphire-20 1px solid;
}

button.btn.button-with-chevron {
  align-items: center;
  display: flex !important;
  gap: 8px;
}

.no-style-button {
  // This removes styles added by default to button elements.
  // For when something should semantically be a button,
  // but isn't buttony in appearance.
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none; // Just in case we missed anything.
}
