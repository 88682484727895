@use "../global-variables" as *;

.account-setup {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: calc(100vh - 100px); // Comment this later if notice that the card centering is not working properly
}

.account-setup > .card {
  padding: 40px 0px;
  width: 868px;
  &.join-card {
    align-items: center;
    text-align: center;
    > div {
      width: 400px;
      display: flex;
      flex-direction: column;
    }
  }

  .btn-primary {
    width: 400px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  button[type="submit"] {
    align-self: center;
  }
  .informational-text {
    color: $charcoal-100;
    font-size: 16px;
    font-weight: 700;
  }
  .form-label {
    color: $charcoal-100;
    font-size: 16px;
    font-weight: 700;
  }
}
