@use "../global-variables" as *;

.empty-skill-focus-card {
  padding: 20px;
  display: flex;
  > h2 {
    margin-bottom: 16px;
  }
}

.empty-card {
  padding: 20px;
  display: flex;
  align-items: center;
  color: $charcoal-100;
  flex-direction: column;
  background-color: $charcoal-05;
  border-radius: 4px;
  text-align: center;

  > span {
    font-size: 16px;
    font-weight: bold;
  }
  > p {
    max-width: 320px;
    font-size: 14px;
    margin: 0;
  }
  > .action-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
    > button.btn-outline-primary {
      background-color: white;
    }
  }
}

.setup-empty-card.card {
  background-color: #f5f7fc;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $charcoal-100;
  text-align: center;
  gap: 32px;
  .card-description {
    max-width: 320px;
  }
  .card-actions,
  .card-content {
    display: flex;
    flex-direction: column;
  }
  .card-actions {
    gap: 20px;
  }
  .card-content {
    gap: 4px;
  }

  h4 {
    color: $charcoal-100;
  }
}
