@use "../global-variables" as *;

.half-donut-chart-wrapper {
  position: relative;
  > .half-donut-chart {
    position: absolute;
  }
}

.half-donut-chart-wrapper.small {
  height: 160px;
  width: 300px;
  > .half-donut-chart {
    margin-top: -65px;
    > .half-donut-text {
      > .score-number {
        color: $sapphire-150;
        font-size: 48px;
        font-weight: 700;
        line-height: initial;
      }
    }
  }
}

.half-donut-chart-wrapper.extra-small {
  margin-top: -40px;
  canvas {
    height: 150px !important;
    width: 150px !important;
  }
  > .half-donut-chart {
    .score-label-extra-small {
      display: block;
      height: 8px;
      width: 38px;
      background-color: $sapphire-10;
      border-radius: 12px;
    }
    .score-number {
      color: $sapphire-150;
      font-size: 24px;
      font-weight: 800;
      margin-top: -8px;
    }
  }
}

.half-donut-text {
  position: absolute;
  bottom: 100px;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  > span {
    color: $charcoal-100;
    font-size: 14px;
    font-weight: 400;
  }
}

.variance-chart {
  width: 100%;
  position: relative;
  border-radius: 8px;
  height: 12px;
  background-color: $charcoal-05;
  > svg {
    position: absolute;
    font-size: 12px;
  }
}

.stack-bar-chart {
  display: grid;
  background-color: $charcoal-05;
  border-radius: 8px;
  gap: 6px;
  font-size: 14px;
  > div {
    border-radius: 8px;
    padding: 4px 8px;
    border: 1px solid $charcoal-05;
    text-align: center;
    &.high {
      background-color: $sapphire-30;
      border-color: $sapphire-100;
    }
    &.medium {
      background-color: $indicator-success-green-20;
      border-color: $indicator-success-green-100;
    }
    &.low {
      background-color: $indicator-alert-yellow-20;
      border-color: $gold-100;
    }
  }
}

.summary-chart-key {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  > div {
    display: flex;
    gap: 6px;
  }
  h4 {
    font-weight: bold;
    font-size: 14px;
    color: black;
    margin-bottom: 0;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  p {
    font-size: 12px;
    color: $charcoal-100;
    margin-bottom: 0;
  }

  > .high > svg {
    color: $sapphire-100;
  }
  > .healthy > svg {
    color: $indicator-success-green-100;
  }
  > .need-help > svg {
    color: $gold-100;
  }

  // on mobile we want to stack the key
  @media (max-width: 991px) {
    flex-direction: column;
    &.team-reflection {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}

.radar-chart-wrapper {
  display: flex;
  flex-direction: column;
}

.agreement-chart {
  border-radius: 8px;
  background: linear-gradient(90deg, #d50057 0%, #ffd600 53.13%, #00c067 100%);
  width: 100%; // Temporary value
  height: 8px;
  position: relative;
  > .negative-space {
    position: absolute;
    background-color: white;
    top: 0;
    opacity: 0.7;
    height: 100%;
  }
  > .avatar-circle-container {
    position: absolute;
    left: 0;
    margin-left: -12px;
    margin-top: -12px;
    text-align: center;
  }
}

.permission-chart-table {
  th {
    color: $charcoal-100;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 12px;
  }
  td {
    font-size: 14px;
    font-weight: 400;
    padding: 12px 0;
  }

  th,
  td {
    text-align: center;
    &.row-name {
      text-align: left;
      max-width: 200px;
    }
  }

  tr:first-child td {
    padding-top: 24px;
  }
  thead {
    border-bottom: 1px solid $charcoal-20;
  }
}

.chart-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  > .chart-legend-item {
    color: $navy-100;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    > .chart-legend-item-color {
      width: 40px;
      height: 12px;
      border-radius: 4px;
    }
  }
}

.line-chart-empty-state {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: $sapphire-05;
  .line-chart-empty-state__illustration {
    width: 100px;
    height: 100px;
  }
  .line-chart-empty-state__title {
    font-size: 16px;
    font-weight: 700;
  }
  .line-chart-empty-state__description {
    margin-top: 8px;
    font-size: 14px;
    max-width: 480px;
  }
  .line-chart-empty-state__title,
  .line-chart-empty-state__description {
    text-align: center;
    color: $sapphire-150;
  }
}

.simple-line-chart-container {
  height: 333px;
}
