@use "/src/styles/global-variables" as *;

.factor-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}

.navigation-box {
  display: flex;
  flex-direction: column;
  height: fit-content;
  z-index: 999;
  position: sticky;
  top: 0;

  > div {
    border: solid 1px $charcoal-20;
    padding: 24px;
    cursor: pointer;
    &.selected {
      background-color: #f5f7fc;
      border: 2px solid #425cc7 !important;
    }
    > span {
      font-size: 16px;
      font-weight: bold;
      color: $navy-100;
    }
    &.active {
      background-color: $sapphire-05;
      border: solid 2px $sapphire-100;
    }
  }
}

.skills-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  @media (max-width: 1199px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.skills-card.card {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  // the first child with the .flex class should have the margin-bottom of 12px
  > .d-flex {
    &:first-child {
      margin-bottom: 12px;
    }
  }
  > button {
    margin-top: auto;
    width: fit-content;
  }
  > h4 {
    margin-bottom: 4px;
  }
  > p {
    margin-bottom: 12px;
    color: $charcoal-100;
  }
}

hr.selected {
  border: solid 1px $sapphire-100;
  opacity: 1;
}

.factor-card-small.card {
  padding: 8px;
  transition-property: border, background-color;
  transition-duration: 0.3s;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  h4,
  p {
    margin-bottom: 0;
  }
  p {
    color: $charcoal-100;
  }
  &:hover,
  &.selected {
    background-color: $sapphire-05;
    border: solid 1px $sapphire-100;
  }
}

.skill-focus-card {
  padding: 20px;
  > span {
    color: $charcoal-100;
  }
  > p {
    color: black;
    font-size: 14px;
    font-weight: bold;
  }
}

.skill-focus-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.skill-focus-card__footer {
  background-color: $sapphire-05;
  color: $sapphire-150;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

.skill-focus-card__footer-text {
  > div {
    @include line-clamp(1);
    > span {
      font-weight: bold;
    }
  }
}

.skills-guide-accordion-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: space-between;
    > span {
      color: $charcoal-100;
      font-size: 14px;
      > svg {
        color: $sapphire-100;
      }
    }
  }
  > h3 {
    margin-bottom: 0;
  }
}

.skill-guide-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}

.skill-guide-section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  > div {
    display: flex;
    gap: 12px;
    align-items: center;
    > h3 {
      color: black;
      margin-bottom: 0;
    }
  }
  h2 {
    width: 160px;
    min-width: 160px;
  }
}

.skill-guide-section {
  display: flex;
  gap: 40px;
  position: relative;
  padding: 8px 0;
  ul {
    margin-bottom: 0;
    margin-left: -16px;
  }
  // when screen size is less than 1200 then change the flex direction to column
  @media (max-width: 1199px) {
    flex-direction: column;
    gap: 16px;
    .edit-circle-container {
      position: absolute;
      right: 0;
    }
    .skill-guide-section-header {
      width: 100%;
      h2 {
        width: 100%;
        min-width: 100%;
      }
    }
  }
  // when screensize 1366px or larger then  .skill-guide-section should have a max width of 1046px
  @media (min-width: 1366px) {
    max-width: 1046px;
  }
}

.edit-circle-container {
  margin-left: auto;
}

.skill-library-intro {
  display: flex;
  gap: 64px;
  // then the children should be half the width of the parent
  > div {
    flex: 1;
  }
  // when screen size is less than 1200 then change the flex direction to column
  @media (max-width: 1199px) {
    flex-direction: column;
    gap: 24px;
  }
}

.medium-square-icon,
.small-square-icon {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $sapphire-05;
  color: $sapphire-150;
  aspect-ratio: 1;
  &.grey {
    background-color: $charcoal-10;
    color: $charcoal-100;
  }
  &.dark-grey {
    background-color: $charcoal-100;
    color: white;
  }
  &.blue {
    background-color: $sapphire-100;
    color: white;
  }
  &.ruby {
    background-color: $ruby-10;
    color: $ruby-100;
  }
  &.cyan {
    background-color: $cyan-10;
  }
  &.dark-sapphire {
    background-color: $sapphire-150;
    color: white;
  }
  &.sapphire-10 {
    background-color: $sapphire-10;
  }
}

.small-square-icon {
  width: 44px;
  min-width: 44px;
  height: 44px;
  font-size: 20px;
}

.medium-square-icon {
  width: 56px;
  min-width: 56px;
  height: 56px;
  font-size: 24px;
}

.skills-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
    > .thumb-buttons {
      display: flex;
      gap: 8px;
    }
  }
  > .skills-page-content {
    gap: 16px;
    > .nav-content {
      padding: 0 12px;
    }
  }
}

.skill-page-header {
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 8px 0;
}

.edit-skill-content-modal-body {
  textarea {
    height: 142px;
    margin-bottom: 12px;
  }
}

.list-span {
  &.Skill {
    color: $sky-130;
    background-color: $sky-10;
  }
  &.Dimension {
    color: $poppy-130;
    background-color: $poppy-10;
  }
}

.skill-search-option {
  margin: 0;
  > span {
    color: $navy-100;
    font-size: 14px;
  }
  > p {
    margin: 0;
    color: $charcoal-100;
  }
}

.factor-intro {
  background-color: $sapphire-05;
  padding: 20px;
  margin: 12px 0;
  display: flex;
  gap: 24px;
  > p {
    color: $charcoal-100;
  }
}

.skill-library.factor-cards-container {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.skill-guide-section-description {
  li {
    font-size: 14px;
  }
  > strong {
    margin-bottom: 6px;
    margin-top: 6px;
    display: inline-block;
    font-size: 14px;
    &:first-of-type {
      margin-top: 0;
    }
  }
}
