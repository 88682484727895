@use "../global-variables" as *;

.image-editing-modal {
  .modal-title-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 12px 16px;
    h2 {
      width: auto;
      display: inline;
    }
    svg {
      color: $sapphire-100;
    }
  }
  .crop-container {
    background-color: $charcoal-100;
    display: flex;
    justify-content: center;
  }
  .ReactCrop__child-wrapper {
    > img {
      height: 300px;
    }
  }

  .upload-picture-modal-footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .remove-guide-photo-button {
    color: #ca003d;
    font-weight: bold;
    font-size: 14px;
    padding-top: 15px;
    &:hover {
      text-decoration: underline;
    }
  }

  &.profilePicture {
    .preview-canvas {
      border-radius: 50%;
    }
    .crop-area .ReactCrop__crop-selection {
      border-radius: 50%;
    }
  }

  .preview-canvas {
    border: 1px solid black;
    object-fit: contain;
    width: 136px;
    height: 136px;
  }
}

.modal-border {
  border-top: 1px solid $sapphire-20;
}

.upload-picture-modal-body {
  display: grid;
  padding: 20px;
  border-bottom: 1px solid $sapphire-20;
  text-align: center;
  align-items: center;
  justify-content: center;
  &.profilePicture {
    grid-template-columns: 3fr 2fr;
  }
  &.coverPhoto,
  &.upload {
    display: flex;
  }
}

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.Crop-Controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}
