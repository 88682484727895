.check-box-container {
  display: flex;
  gap: 8px;
  > label {
    font-size: 13px;
  }
}

.checklist {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
